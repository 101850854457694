import { Flex, Link, Text } from "@radix-ui/themes";
import {
  type ErrorComponentProps,
  type ErrorRouteComponent,
  // eslint-disable-next-line import/named
  Link as NavLink,
} from "@tanstack/react-router";
import cx from "classnames";
import { useEffect, type FC, type PropsWithChildren } from "react";

import { useAppSelector } from "../../../../../utils/hooks";
import {
  captureError,
  ErrorAction,
} from "../../../../root/customErrorComponent";
import { ticketsRoute } from "../../../route";

type ErrorProps = ErrorComponentProps & {
  error: Error | Response;
};

const ErrorComponent: ErrorRouteComponent = (props: ErrorProps) => {
  const { error } = props;

  useEffect(() => {
    captureError("ticket_error", error);
  }, []); // Runs only once on mount

  function renderError() {
    if ("status" in error) {
      switch (error.status) {
        case 404:
        case 500:
          return <NotFound />;
        case 401:
          return <Unauthorized />;
        default:
          return <DefaultError />;
      }
    }
  }

  return renderError();
};

export default ErrorComponent;

function NotFound() {
  return (
    <ErrorContainer>
      <h1 className="p-5 text-3xl">Ticket Not Found</h1>
      <Text weight="medium">
        The access provided by the link you followed does not match the current
        ticket URL.
      </Text>
      <Text>Perhaps the link was accidentally changed.</Text>
      <ErrorAction />
    </ErrorContainer>
  );
}

function Unauthorized() {
  return (
    <ErrorContainer>
      <Text weight="medium">This ticket was not found today!</Text>
      <Text>Perhaps it was deleted.</Text>
      <Text>
        Please return to the{" "}
        <Link asChild>
          <NavLink to={ticketsRoute.to}>tickets page</NavLink>
        </Link>{" "}
        to get your task completed.
      </Text>
      <ErrorAction withButtons={false} />
    </ErrorContainer>
  );
}

function DefaultError() {
  return (
    <ErrorContainer>
      <h1 className="p-5 text-3xl">An Unknown Error Occurred</h1>
      <ErrorAction />
    </ErrorContainer>
  );
}

/**
 * A simple container component used to wrap error text. Positions the text to
 * the very center of the page.
 */
const ErrorContainer: FC<PropsWithChildren> = ({ children }) => {
  const { drawerOpen } = useAppSelector((state) => state.ui);
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      height="100vh"
      position="fixed"
      className={cx("w-full text-center", {
        "ml-80": drawerOpen,
        "w-[calc(100vw-20rem)]": drawerOpen,
      })}
    >
      {children}
    </Flex>
  );
};
