import { Flex } from "@radix-ui/themes";
import { useEffect, useState } from "react";

import { Markdown } from "./Markdown";

export type PreviewProps = {
  url?: string;
  pageNumber?: number;
  text?: string;
  textPrefix?: string;
};

export function urlToPreviewType(url?: string, text?: string) {
  if (!url) return text ? "text" : "unknown";
  if (/\?|#/.test(url)) return "iframe";
  if (url.endsWith(".pdf")) return "application/pdf";
  if (url.endsWith(".md")) return "text/markdown";
  if (url.endsWith(".txt")) return "text/plain";
  if (/\.(?:jpe?g|png|gif|bmp|tiff|webp)$/.test(url))
    return "image/" + url.split(".").pop();
  if (/\.(?:mp4|webm|ogv)$/.test(url)) return "video/" + url.split(".").pop();
  if (/(?<!\?|#)\.(?:docx?|xlsx?|pptx?|odt|ods|odp|csv)$/.test(url))
    return "download";
  return "iframe";
}

export function Preview(props: PreviewProps) {
  const { url, pageNumber, text, textPrefix } = props;
  const previewType = urlToPreviewType(url, text);

  const [mdText, setMdText] = useState("Loading...");
  const [pdfBlobUrl, setPdfBlobUrl] = useState<string>();

  useEffect(() => {
    const fetchMarkdown = async () => {
      if (previewType !== "text/markdown" || !url) return;
      try {
        const md = await (await fetch(url)).text();
        setMdText(md);
      } catch (error) {
        setMdText("Error loading file: " + String(error));
      }
    };
    fetchMarkdown();
  }, [url, previewType]);

  useEffect(() => {
    const fetchPdf = async () => {
      if (previewType !== "application/pdf" || !url) return;

      // Revoke the previous blob URL if it somehow changed without cleanup
      if (pdfBlobUrl) URL.revokeObjectURL(pdfBlobUrl);

      try {
        const response = await fetch(
          url +
            "?response-content-disposition=inline&response-content-type=application%2Fpdf",
        );
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);
        setPdfBlobUrl(blobUrl);
      } catch (e) {
        console.error("Error loading PDF:", e);
      }
    };
    fetchPdf();

    return () => {
      if (pdfBlobUrl) {
        URL.revokeObjectURL(pdfBlobUrl);
      }
    };
  }, [url, previewType]);

  return (
    <Flex
      justify={url ? "center" : "start"}
      className="h-[calc(100%-48px)] w-full place-items-center"
    >
      {previewType === "application/pdf" ? (
        <object
          data={
            pdfBlobUrl ? `${pdfBlobUrl}#page=${pageNumber ?? 1}` : undefined
          }
          type="application/pdf"
          width="100%"
          height="100%"
          className="border-puntt-neutral-6 grow border"
        >
          {!pdfBlobUrl ? (
            "Loading PDF..."
          ) : (
            <>
              Your browser does not support PDF embedding.{" "}
              <a
                href={url}
                target="_blank"
                rel="noreferrer"
                className="text-puntt-accent-12 hover:text-puntt-accent-11 active:text-puntt-accent-10"
              >
                Download it instead.
              </a>
            </>
          )}
        </object>
      ) : previewType.startsWith("image/") ? (
        <img src={url} alt="Citation source" />
      ) : previewType.startsWith("video/") ? (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video src={url} controls preload="metadata">
          Your browser does not support video embedding.{" "}
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            className="text-puntt-accent-12 hover:text-puntt-accent-11 active:text-puntt-accent-10"
          >
            Download it instead.
          </a>
        </video>
      ) : previewType === "download" ? (
        <a
          href={url}
          download
          className="text-puntt-accent-12 hover:text-puntt-accent-11 active:text-puntt-accent-10"
        >
          Download {url?.split("/").pop()}
        </a>
      ) : previewType === "text/plain" ? (
        <object
          data={url}
          type="text/plain"
          width="100%"
          height="100%"
          className="border-puntt-neutral-6 grow border"
        >
          Your browser does not support plain text embedding.{" "}
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            className="text-puntt-accent-12 hover:text-puntt-accent-11 active:text-puntt-accent-10"
          >
            Download it instead.
          </a>
        </object>
      ) : previewType === "text/markdown" ? (
        <Markdown>{mdText}</Markdown>
      ) : previewType === "iframe" ? (
        <iframe
          src={url}
          title="Citation source"
          width="100%"
          height="100%"
          className="border-puntt-neutral-6 grow border"
        />
      ) : previewType === "text" ? (
        <article>
          <p className="pb-2 text-puntt-neutral-gray-11">
            <em>{textPrefix}</em>
          </p>
          <Markdown>{text}</Markdown>
        </article>
      ) : (
        <p>Sorry, we can&apos;t preview this file type.</p>
      )}
    </Flex>
  );
}
