import { Eye, EyeClosed } from "@phosphor-icons/react";
import { Button, Grid, Heading, Text, TextField, Link } from "@radix-ui/themes";
/* eslint-disable-next-line import/named */
import { Link as RouterLink } from "@tanstack/react-router";
import cx from "classnames";
import { useReducer, type FormEventHandler } from "react";

import { forgotPasswordRoute } from "../forgot-password/route";

interface LoginFormProps {
  formRef: React.RefObject<HTMLFormElement>;
  isValid: boolean;
  onSubmit: FormEventHandler<HTMLFormElement>;
  checkValidity: () => void;
  loading: boolean;
  disabled: boolean;
  error: boolean;
}

export function LoginForm({
  formRef,
  isValid,
  onSubmit,
  checkValidity,
  loading,
  disabled,
  error,
}: LoginFormProps) {
  const [isPasswordVisible, togglePasswordVisibility] = useReducer(
    (x) => !x,
    false,
  );

  return (
    <>
      <Heading
        as="h1"
        weight="medium"
        className="mb-8 text-2xl"
        data-testid="login-description"
      >
        Log in to your account.
      </Heading>

      <form onSubmit={onSubmit} className="grid gap-6" ref={formRef}>
        <Grid gap="3">
          <Text weight="medium" size="3" data-testid="email-field-label">
            Work Email
          </Text>
          <TextField.Root
            required
            variant="classic"
            name="email"
            id="email-input"
            placeholder="name@work-email.com"
            type="email"
            autoComplete="true"
            disabled={disabled}
            onInput={checkValidity}
            autoFocus
            className="[&>input]:w-full [&>input]:p-3"
            data-testid="email-field"
          />
          <Text
            color="red"
            size="2"
            data-testid="email-error-message"
            className={cx({ hidden: !error })}
          >
            Invalid or unrecognized email address
          </Text>
        </Grid>

        <Grid gap="3">
          <Text weight="medium" size="3" data-testid="password-field-label">
            Password
          </Text>
          <TextField.Root
            required
            variant="classic"
            onInput={checkValidity}
            name="password"
            id="password-input"
            placeholder="Enter your password"
            type={isPasswordVisible ? "text" : "password"}
            autoComplete="true"
            disabled={disabled}
            className="[&>input]:w-full [&>input]:p-3"
            data-testid="password-field"
          >
            <TextField.Slot
              side="right"
              className="px-3"
              onClick={togglePasswordVisibility}
            >
              {isPasswordVisible ? <EyeClosed size={20} /> : <Eye size={20} />}
            </TextField.Slot>
          </TextField.Root>
          <Text
            color="red"
            size="2"
            data-testid="password-error-message"
            className={cx({ hidden: !error })}
          >
            Invalid email or password combination
          </Text>
        </Grid>

        <Button
          type="submit"
          disabled={!isValid || disabled}
          loading={loading}
          className="mt-2 w-full py-3 font-medium"
          data-testid="form-submit-cta"
        >
          Log In
        </Button>
        <RouterLink
          to={forgotPasswordRoute.to}
          data-testid="forgot-password-link"
          className="w-max text-puntt-blue-12 underline decoration-puntt-accent-5 underline-offset-4 hover:text-puntt-blue-11 active:text-puntt-blue-10"
        >
          Forgot password
        </RouterLink>
      </form>
    </>
  );
}
