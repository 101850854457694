import { Flex, Grid } from "@radix-ui/themes";
import { usePostHog } from "posthog-js/react";
import {
  createContext,
  type RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { type Editor } from "tldraw";

import TicketCanvas from "./components/ticket-canvas";
import TicketHeader from "./components/TicketHeader";
import { ticketRoute } from "./route";

import { isNil } from "../../../../utils/fp";
import { useAppSelector } from "../../../../utils/hooks";
import { CommentDrawer } from "../components/comment-drawer";

type TicketContextValues = {
  /**
   * Whether or not the user has initiated an AI Review from anywhere on the
   * page.
   */
  hasInitiatedAiReview: boolean;
  /**
   * Handler to set whether the user has initiated an AI Review
   */
  setHasInitiatedAiReview(value: boolean): void;
  /**
   * The TLDraw Editor object, used by other components on the page, such as
   * `CommentDrawer`, and to create helper functions to navigate to shapes (e.g.
   * if the user clicks a page in the document thumbnail sidebar in doc mode).
   * This value must only be set by a child descendant that implements TLDraw
   * and no other component should set this value. If no TLDraw instance has
   * been loaded, `editor` will be `undefined`.
   */
  editor?: Editor;
  /**
   * Sets the editor object. It is important to remember that only TLDraw
   * should set this value where it is implemented. There should not be multiple
   * references to TLDraw on the page, and this value must not be set by anything
   * other than one instance of TLDraw.
   */
  setEditor(editor?: Editor): void;
  /**
   * Some to-be-removed controls for video mode.
   */
  videoRef: RefObject<{
    handleCommentClicked: (_seekTime: number) => void;
    duration: number;
  }>;
};

const TicketContext = createContext({} as TicketContextValues);
export const useTicket = () => useContext(TicketContext);

export default function View() {
  // Router methods
  const { ticketId } = ticketRoute.useParams();

  // Slices
  const { value: ticket, isLoading } = useAppSelector((state) => state.ticket);

  // Analytics
  const posthog = usePostHog();

  // Local state
  const [hasInitiatedAiReview, setHasInitiatedAiReview] = useState(false);
  const [editor, setEditor] = useState<Editor>();
  // TODO: remove this because refs and imperative handlers abstract the code
  // in ways that reduce readability and make the code harder to understand.
  const videoRef = useRef<{
    handleCommentClicked: (_seekTime: number) => void;
    duration: number;
  }>(null);

  // Effect chain

  useEffect(() => {
    const context = {
      ticketId,
      ticketTitle: ticket?.title,
    };
    posthog.register(context);

    return () => {
      Object.keys(context).forEach((key) => {
        posthog.unregister(key);
      });
    };
  }, []);

  /////
  // No hooks beyond this point
  /////

  // If for some reason the API responded with a nullish ticket, or the parser
  // failed and return undefined.
  if (isNil(ticket)) {
    if (isLoading) {
      return (
        <Flex
          direction="column"
          align="center"
          justify="center"
          height="100vh"
          width="100vw"
          position="fixed"
          className="text-center"
        >
          Loading&hellip;
        </Flex>
      );
    }
    throw { status: 404 };
  }

  return (
    <TicketContext.Provider
      value={{
        hasInitiatedAiReview,
        setHasInitiatedAiReview,
        editor,
        setEditor,
        videoRef,
      }}
    >
      <Grid
        className="h-[calc(100vh_-_57px)] grid-cols-[1fr_auto] grid-rows-[auto_1fr] [&:has([data-mode=video])]:bg-puntt-neutral-gray-5"
        data-testid="ticket-layout"
      >
        <TicketHeader />

        <TicketCanvas />

        <CommentDrawer editor={editor} videoRef={videoRef} />
      </Grid>
    </TicketContext.Provider>
  );
}
