import { Heading } from "@radix-ui/themes";
import cx from "classnames";
import {
  type ReactNode,
  type FC,
  type PropsWithChildren,
  useState,
  useRef,
  useCallback,
} from "react";

import { ReactComponent as LeftCaret } from "../../images/left-caret-arlo.svg";
import { ReactComponent as LoginCarousel1 } from "../../images/logincarousel1.svg";
import { ReactComponent as LoginCarousel2 } from "../../images/logincarousel2.svg";
import { ReactComponent as LoginCarousel3 } from "../../images/logincarousel3.svg";
import { ReactComponent as LoginCarousel4 } from "../../images/logincarousel4.svg";
import { ReactComponent as Logo } from "../../images/Logotype.svg";
import { ReactComponent as RightCaret } from "../../images/right-caret-arlo.svg";
import { generateUUID } from "../../utils/uuid";

// Update carousel slides with all 4 images
const CAROUSEL_SLIDES = [
  {
    image: LoginCarousel1,
    subtitle: "Centralized Asset Hub",
  },
  {
    image: LoginCarousel2,
    subtitle: "AI Tailored Reviews",
  },
  {
    image: LoginCarousel3,
    subtitle: "Instant AI Assistant",
  },
  {
    image: LoginCarousel4,
    subtitle: "Accelerate Reviews and Approvals",
  },
];

function LoginCarousel() {
  const containerId = useRef(generateUUID());
  const carouselRef = useRef<HTMLDivElement>(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const scrollIntoView = useCallback((index: number) => {
    if (!carouselRef.current?.children[index]) return;
    carouselRef.current.style.transform = `translateX(-${index * 32}rem)`;
  }, []);

  const handleNext = useCallback(() => {
    setCurrentIndex((curr) => {
      const next = curr === CAROUSEL_SLIDES.length - 1 ? 0 : curr + 1;
      scrollIntoView(next);
      return next;
    });
  }, [scrollIntoView]);

  const handlePrevious = useCallback(() => {
    setCurrentIndex((curr) => {
      const next = curr === 0 ? CAROUSEL_SLIDES.length - 1 : curr - 1;
      scrollIntoView(next);
      return next;
    });
  }, [scrollIntoView]);

  return (
    <div className="relative overflow-x-hidden rounded-2xl bg-puntt-neutral-gray-1 py-8 shadow-lg">
      <Heading
        as="h1"
        size="7"
        className="text-gray-900 mb-8 text-center text-[2.25rem] font-normal"
      >
        Automate Your Creative Reviews with AI
      </Heading>
      <figure
        ref={carouselRef}
        className="carousel-container flex h-auto snap-x snap-mandatory scroll-smooth rounded-[inherit] transition-transform duration-500"
        data-container-id={containerId.current}
      >
        {CAROUSEL_SLIDES.map((slide) => {
          const SlideImage = slide.image;
          return (
            <div
              key={slide.subtitle}
              className="flex size-full w-[32rem] flex-none snap-start flex-col"
            >
              <div className="mb-8 flex flex-1 items-center justify-center">
                <SlideImage className="h-auto max-h-[400px] object-contain" />
              </div>
              <div className="mb-4 text-center">
                <p className="text-gray-600 text-xl font-normal">
                  {slide.subtitle}
                </p>
              </div>
            </div>
          );
        })}
      </figure>
      <div className="mt-auto flex w-full items-center justify-center pt-0">
        <section className="flex w-3/4 items-center justify-center">
          <button
            onClick={handlePrevious}
            className="flex size-10 items-center justify-center bg-base-white"
            aria-label="Previous slide"
          >
            <LeftCaret className="size-6 hover:bg-puntt-neutral-gray-4" />
          </button>

          <nav className="flex gap-3">
            {CAROUSEL_SLIDES.map((_, i) => (
              <button
                className="rounded-full bg-base-white p-1"
                onClick={() => {
                  scrollIntoView(i);
                  setCurrentIndex(i);
                }}
                key={i}
                aria-label={`Go to slide ${i + 1}`}
              >
                <span
                  className={cx(
                    "block h-3.5 w-3.5 rounded-full border-4 bg-puntt-neutral-gray-2",
                    {
                      "border-puntt-neutral-gray-9": currentIndex === i,
                      "border-puntt-neutral-gray-3": currentIndex !== i,
                    },
                  )}
                />
              </button>
            ))}
          </nav>

          <button
            onClick={handleNext}
            className="flex size-10 items-center justify-center bg-base-white"
            aria-label="Next slide"
          >
            <RightCaret className="size-5 hover:bg-puntt-neutral-gray-4" />
          </button>
        </section>
      </div>
    </div>
  );
}

export const LoginLayout: FC<
  { customCaption?: ReactNode } & PropsWithChildren<any>
> = ({ children }) => {
  return (
    <section className="bg-gray-100 flex min-h-screen w-full items-start justify-center px-1 py-9 md:px-8">
      <div className="w-full md:flex md:items-start md:justify-between md:gap-16 lg:justify-center">
        {/* Left Column - Login Form */}
        <article className="bg-white w-full rounded-2xl p-2 md:w-[40vw] md:min-w-[320px] md:max-w-[512px] md:basis-3/5 md:p-2 lg:basis-auto lg:p-8">
          <div className="w-full">
            <Logo className="mb-12 h-auto w-[160px]" />
            <hr className="mb-12 border-base-black" />
            {children}
          </div>
        </article>

        {/* Right Column - Carousel */}
        <article className="bg-white hidden w-[40vw] min-w-[320px] max-w-[512px] md:block">
          <div className="w-full">
            <LoginCarousel />
          </div>
        </article>
      </div>
    </section>
  );
};
